<template>
  <div class="pageMain">
    <div v-if="infoShow">
      <search-head
        :model="searchForm"
        @search="search"
        @reset="reset"
        :isExport="true"
      >
        <el-form :inline="true" :model="searchForm">
          <!-- <el-form-item label="所属机构" prop="orgaIdArr">
              <el-cascader
                  size="small"
                  class="block-select"
                  v-model="searchForm.orgaIdArr"
                  :options="copanyListInfo"
                  popper-class="custom-cascader"
                  filterable
                  :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                   
                  }"
                  @change="cascaderEditChange">
              </el-cascader>
            </el-form-item> -->
          <el-form-item label="序列号">
            <el-input
              v-model="searchForm.cameSn"
              size="small"
              placeholder="序列号"
            ></el-input>
          </el-form-item>
          <el-form-item label="接入类型">
            <el-select
              v-model="searchForm.cameraType"
              size="small"
              placeholder="接入类型"
            >
              <el-option
                v-for="item in cameTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-input
              v-model="searchForm.projName"
              size="small"
              placeholder="项目名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="终端编号">
            <el-input
              v-model="searchForm.deviceSn"
              size="small"
              placeholder="终端编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="接入名称">
            <el-input
              v-model="searchForm.cameType"
              size="small"
              placeholder="接入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="searchForm.cameStatus"
              size="small"
              placeholder="状态"
            >
              <el-option
                v-for="item in cameStatusList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </search-head>
      <custom-tables
        :data="tableData"
        :headerCellStyle="headerCellStyle"
        @selectionChange="handleSelectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :total="total"
        title="项目设备"
      >
        <!-- <template slot="btns">
            <el-button type="primary" size="small" @click="addFrom">新建</el-button>
          </template> -->

        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column prop="cameSn" label="序列号" show-overflow-tooltip  width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="cameraType"
          label="接入类型"
          align="center" 
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.cameraType == 0">萤石云</span>
            <span v-if="scope.row.cameraType == 1">自有 </span>
            <span v-if="scope.row.cameraType == 2">博瓦</span>
            <!-- <span v-if="scope.row.deviceType == 3">架桥机</span>
                <span v-if="scope.row.deviceType == 4">爬模</span>
                <span v-if="scope.row.deviceType == 5">挂篮</span> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="cameType"
          align="center"
          label="接入名称"
          width="140"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="cameChannel"
          align="center"
          label="通道号"
          width="80"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="deviceSn"
          align="center"
          label="终端编号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="projName" label="项目名称" show-overflow-tooltip    >
        </el-table-column>
        <el-table-column
          prop="cameStatus"
          label="设备状态"
          width="80"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.cameStatus == 0" type="success"
              >使用</el-tag
            >
            <el-tag v-if="scope.row.cameStatus == 1" type="warning"
              >闲置</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="cameNote"
          align="center"
        
          label="备注说明"
          show-overflow-tooltip
        >
        </el-table-column>

        <!-- <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span @click="detailItem(scope.row)" class="detail">查看</span>
            </template>
          </el-table-column> -->
      </custom-tables>
    </div>
    <!-- <monitorAlarm v-if="editShow" :compId="compId"></monitorAlarm> -->
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import CustomDialog from "../../../components/common/CustomDialog";
import organizationTree from "../../../api/modules/organizationTree";
import monitor from "../../../api/modules/monitor";
import {
  equipType,
  deviceOnline,
  generationList,
  cameStatusList,
  cameTypeList,
} from "../../../config/dataStatus";
import {
  organizationCascaders,
  phoneReg,
  organizationCascader,
} from "../../../utils/tool";

export default {
  name: "index",
  components: { CustomDialog, CustomTables, SearchHead },
  data() {
    return {
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      copanyListInfo: [],
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      editShow: false,
      infoShow: true,
      compId: "",
      deviceOnline: deviceOnline,
      equipType: equipType,
      generationList: generationList,
      cameStatusList: cameStatusList,
      cameTypeList: cameTypeList,
    };
  },
  mounted() {
    this.getList();
    this.getCopanyInfo();
  },
  methods: {
    getCopanyInfo() {
      organizationTree.getOrganizationCompanyTree().then((res) => {
        // 递归遍历 生成 {value, label, children}

        // let data = organizationCascaders(res.data);
        // console.log('data',data);

        this.copanyListInfo = organizationCascader(res.data);
      });
    },
    getList() {
      monitor.getCameraSelect(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    cascaderEditChange(val) {
      console.log("val", val);

      let valLen = val.length;
      this.searchForm.orgaId = val[valLen - 1];
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
    addFrom() {
      this.showDialog = true;
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.currPage = 1;
      this.getList();
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      this.showDialog = false;
    },
    detailItem(item) {
      //详情
      if (item.generation == 6) {
        this.$router.push({
          path: `/screnDetail`,
          query: {
            url: item.cameChannel,
            type: item.type,
            title: item.deviceImei,
            deviceType: item.deviceType,
            projId: item.projId,
            deviceOnline: item.deviceOnline,
            deviceId: item.deviceId,
            deviceNo: item.deviceNo,
          },
        });
      } else if (item.generation == 5) {
        this.$router.push({
          path: `/fiveScren`,
          query: {
            url: item.cameChannel,
            type: item.type,
            title: item.deviceImei,
            deviceType: item.deviceType,
            projId: item.projId,
            deviceOnline: item.deviceOnline,
            deviceId: item.deviceId,
            deviceNo: item.deviceNo,
          },
        });
      }
    },
    handleSelectionChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="less"></style>
